.active-link {
    background-color: rgba(231,231,231,0.5);

    color: black;

    &:active{
        color: black;
    }
    &:visited {
        color: black;
    }
    &:hover {
        color: black;
    }
    &:link {
        color: black;
    }
}
.inactive-link {
    color: rgba(0,0,0,0.7);

    &:active{
        color: rgba(0,0,0,0.7);
    }
    &:visited {
        color: rgba(0,0,0,0.7);
    }
    &:hover {
        color: rgba(0,0,0,0.7);
    }
    &:link {
        color: rgba(0,0,0,0.7);
    }
}

